import React, { useEffect, useState } from 'react';
import "../css/homepage.css";
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import HeroLR from '../components/HeroLR';
import SectionLRButton from '../components/SectionLRButton';
import Eto from '../components/ETO';
import ServiceCard from '../components/serviceCard';
import BrandCard from '../components/brandCard';
import { Link } from 'react-router-dom';

function HomePage() {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingBrands, setLoadingBrands] = useState(true);

  useEffect(() => {
    fetch('/data/ProductData.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setProducts(data);
        setLoadingProducts(false);
      })
      .catch(error => {
        console.error('Error fetching product data:', error);
        setLoadingProducts(false);
      });
  }, []);

  useEffect(() => {
    fetch('/data/BrandData.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(brandData => {
        setBrands(brandData);
        setLoadingBrands(false);
      })
      .catch(error => {
        console.error('Error fetching brand data:', error);
        setLoadingBrands(false);
      });
  }, []);

  if (loadingProducts || loadingBrands) {
    // return <div>Loading...</div>;
  }

  const featuredProducts = products
    .filter(product => product.featured)
    .sort((a, b) => a.featured - b.featured)
    .slice(0, 8);

  const featuredBrands = brands
    .filter(brand => brand.featured)
    .sort((a, b) => a.featured - b.featured)
    .slice(0, 26);

  return (
    <div>
      <NavBar />

      <HeroLR 
        header="UNIQUE TOOLS FOR ALL YOUR INDUSTRY NEEDS"
        text="Welcome to Unique Tools Company (UTC), your top destination for quality tools and equipment in Saudi Arabia."
        image="/images/homepage/hero-img.webp"
        LR="default"
        bg="/images/homepage/hero-bg.webp"
      />

      <Eto />

      <div className='services-section service'>
        <h2>EXPLORE PRODUCTS</h2>
        <p></p>

        <div className='service-cards'>
          {featuredProducts.map(product => (
            <ServiceCard
              key={product.id}
              id={product.id}
              title={product.title}
              imgURL={product.imgURL}
            />
          ))}
        </div>

      <Link to="/products" >
          <button className='button button-home'>All Products</button>
          </Link>

      </div>



      <div className='image-break'>
        <img src='/images/homepage/break.webp'/>
      </div>

      <div className='brands'>
        <h2>OUR PARTNERS</h2>
        <p>Uhas been a proud and active participant in the Saudi market since 2015. We take great pride in our numerous successful partnerships, which have significantly contributed to our growth and success.</p>
<p></p>
        <div className='brand-cards'>
          {featuredBrands.map(brand => (
            <BrandCard
              key={brand.id}
              id={brand.id}
              title={brand.title}
              imgURL={brand.imgURL}
            />
          ))}
        </div>
      </div>

      <SectionLRButton 
            header="Alfaw Workshops for Car and Heavy Equipment Maintenance"
            text="UTC Unique Tools is proud to partner with Alfaw Workshops for Car and Heavy Equipment Maintenance."
            image="/images/homepage/faw.webp"
            // image="url('/images/Stock photo.jpeg')"
            LR="default"
            // LR="default" -> text on the left
            // LR="flip" -> text on the right
        />

      <Footer />
    </div>
  );
}

export default HomePage;
