import React from 'react';
// import { Link } from 'react-router-dom';
// import { kebabCase } from 'lodash';
import styled from 'styled-components';

// const BrandCardContainer = styled.div`
const BrandCardContainer = styled.div`
  position: relative;
  width: 7vw;
  ${'' /* height: 15vw; */}
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* flex-grow: 1; */}
  flex: 0 0 calc(11.11% - 13.33px); /* 4 columns with gap consideration */
  ${'' /* border-radius: 25px; */}
  flex-direction: column;
  background: white;
  ${'' /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  text-decoration: none; */}

  @media (max-width: 768px) {
    ${'' /* width: 25vw; */}
    ${'' /* height: 25vw; */}
    flex: 0 0 calc(14.28% - 12.85px); /* 4 columns with gap consideration */
  }

  @media (max-width: 520px) {
    ${'' /* width: 150px;
    height: 150px; */}
    flex: 0 0 calc(20% - 12px); /* 4 columns with gap consideration */
  }
`;

const Image = styled.img`
  position: relative;
  width: 80%;
  height: 80%;
  object-fit: contain;
`;

function BrandCard(props) {
  return (
    <BrandCardContainer key={props.id}>
      {/* <Overlay /> */}
      <Image src={props.imgURL} alt="" />
      {/* <Title>{props.title}</Title> */}
    </BrandCardContainer>
  );
}

export default BrandCard;
