import React from 'react';
import { Link } from 'react-router-dom';
import "../css/footer.css"


function Footer() {
    const currentDate = new Date(); // Get the current date
    const year = currentDate.getFullYear();

    return (
      <footer>
        <div className='footer'>
        <div className='footer-columns'>
        <div className='footer-column'>
          <div className='column-block'>
          
            <img src="/images/logo/utc.webp" alt=''></img>
       
         
          </div>
        </div>
        <div className='footer-column mid-column'>
        <h5>Quick Links</h5>
        <Link to='/'>Home</Link>
          <Link to='/about'>About</Link>
          <Link to='/products'>Products</Link>
          <Link to='/contact'>Contact</Link>
          
        </div>
        <div className='footer-column'>

        <div className='smicons'>
            {/* <a href='https://www.linkedin.com/company/xxx' target='_blank' className='smicon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg></a> */}
            <a href='https://wa.me/966542375662' target='_blank' className='smicon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 122.8c-72.7 0-131.8 59.1-131.9 131.8 0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6 49.9-13.1 4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8 0-35.2-15.2-68.3-40.1-93.2-25-25-58-38.7-93.2-38.7zm77.5 188.4c-3.3 9.3-19.1 17.7-26.7 18.8-12.6 1.9-22.4.9-47.5-9.9-39.7-17.2-65.7-57.2-67.7-59.8-2-2.6-16.2-21.5-16.2-41s10.2-29.1 13.9-33.1c3.6-4 7.9-5 10.6-5 2.6 0 5.3 0 7.6.1 2.4.1 5.7-.9 8.9 6.8 3.3 7.9 11.2 27.4 12.2 29.4s1.7 4.3.3 6.9c-7.6 15.2-15.7 14.6-11.6 21.6 15.3 26.3 30.6 35.4 53.9 47.1 4 2 6.3 1.7 8.6-1 2.3-2.6 9.9-11.6 12.5-15.5 2.6-4 5.3-3.3 8.9-2 3.6 1.3 23.1 10.9 27.1 12.9s6.6 3 7.6 4.6c.9 1.9.9 9.9-2.4 19.1zM400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM223.9 413.2c-26.6 0-52.7-6.7-75.8-19.3L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5 29.9 30 47.9 69.8 47.9 112.2 0 87.4-72.7 158.5-160.1 158.5z"/></svg></a>
            <a href='mailto:utc@uniquetools-utc.com' target='_blank' className='smicon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM218 271.7L64.2 172.4C66 156.4 79.5 144 96 144H352c16.5 0 30 12.4 31.8 28.4L230 271.7c-1.8 1.2-3.9 1.8-6 1.8s-4.2-.6-6-1.8zm29.4 26.9L384 210.4V336c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V210.4l136.6 88.2c7 4.5 15.1 6.9 23.4 6.9s16.4-2.4 23.4-6.9z"/></svg></a>
            <a href='tel:+966 537966555' target='_blank' className='smicon'><svg class="svg-icon"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M864 64H160A96 96 0 0 0 64 160v704a96 96 0 0 0 96 96h704a96 96 0 0 0 96-96V160a96 96 0 0 0-96-96z m-32.78 614.74l-30 130A30 30 0 0 1 772 832C452 832 192 572.58 192 252a31.4 31.4 0 0 1 23.26-29.22l130-30A36.46 36.46 0 0 1 352 192a32.54 32.54 0 0 1 27.58 18.18l60 140A35.8 35.8 0 0 1 442 362a34 34 0 0 1-11 23.22l-75.78 62a463.82 463.82 0 0 0 221.56 221.56l62-75.78A34 34 0 0 1 662 582a35.7 35.7 0 0 1 11.82 2.42l140 60A32.5 32.5 0 0 1 832 672a34.82 34.82 0 0 1-0.78 6.74z"  /></svg></a>

          </div>
          
          <Link to="/contact" className='button-contact'>
          <button className='button'>Contact Us</button>
          </Link>
        

          {/* <h5>Contact</h5>
          <p>email</p>
          <p>phone</p>
          <p>address</p>
          <h6>Join our team</h6>
          <p>Send your resume to xxx</p>
          <a href=''>click here</a> */}

        </div>
        </div>
        <div className='footer-copyright'>
          <p></p>
          <p className='copyright'>&copy; UTC {year}. All rights reserved.</p>
          <p className='nrldn'>Powered by <a href="https://nrldn.com" target='_blank'>nrldn</a></p>

        </div>
        </div>
        
      </footer>
    );
  }

  export default Footer;


  