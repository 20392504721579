import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  position: relative;
  width: 15vw;
  ${'' /* height: 250px; */}
  max-height: 274px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* flex-grow: 1; */}
  flex: 0 0 calc(25% - 15px); /* 4 columns with gap consideration */
  border-radius: 0;
  flex-direction: column;
  background: white;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

  @media (max-width: 1200px) {
    width: 20vw;
    ${'' /* height: 200px; */}
  }

  @media (max-width: 768px) {
    width: 25vw;
    ${'' /* height: 200px; */}
    flex: 0 0 calc(33.33% - 13.333px); /* 3 columns with gap consideration */

  }

  @media (max-width: 520px) {

    flex: 0 0 calc(50% - 10px); /* 2 columns with gap consideration */
  }


`;

const Image = styled.img`
  position: relative;
  top: 10px;
  width: 80%;
  height: 80%;
  object-fit: contain;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

const Title = styled.p`
  width: 55%;
  text-align: center;
  z-index: 98;
  font-size: 1.5rem;
  color: black;
  margin: 5px 5px 10px 5px;


  @media (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

function ProductCard(props) {
  return (
    <CardContainer key={props.id}>
      <Image src={props.imgURL} alt="" />
      {/* Uncomment the following line if you want to display the title */}
      {/* <Title>{props.title}</Title> */}
    </CardContainer>
  );
}

export default ProductCard;
