import React from 'react';
import "../css/contact.css"
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { ContactUs } from '../components/contactForm';



function Contact() {

    return(
    <div>
        <NavBar />

        <Header 
      title = "CONTACT US"
      subtext = "Reach out to us for inquiries, technical support, or feedback. Experience our exceptional service firsthand."
      imgURL = "/images/banners/header-01.webp"
    />

<div className='contact'>
<div className='text-left'>
      <div className='text'>
      <h3 className='form-header'>Contact Information</h3>
      <p>Email: utc@uniquetools-utc.com</p>
      <p>Phone: +966 537966555 / +966 552625550</p>
      <p>P.O. Box: 30149 Yanbu 41911</p>
      <p>Address: Yanbu Al Bahr - Redsea Industrial - Al Naqadi</p>
      

        </div>

        <div className='map-image'>
        <img src='/images/contact/contact2.webp' alt=''></img>
        </div>
    </div>
    </div>

<div className='contact'>
<div className='text-left'>
      <div className='text'>
      <h3 className='form-header'>Send Message</h3>
      
        <ContactUs />
      
        </div>

        <div className='image'>
        <img src='/images/contact/contact.webp' alt=''></img>
        </div>
    </div>
    </div>


        <Footer />
    </div>
    )
}

export default Contact;