import React from 'react';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import styled from 'styled-components';

const ServiceCardContainer = styled(Link)`
  position: relative;
  width: 15vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* flex-grow: 1; */}
  flex: 0 0 calc(25% - 22.5px); /* 4 columns with gap consideration */
  border-radius: 25px;
  flex-direction: column;
  background: white;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  text-decoration: none;



  @media (max-width: 768px) {
    width: 30vw;
    height: 30vw;
    flex: 0 0 calc(33.33% - 20px); /* 4 columns with gap consideration */

  }

  @media (max-width: 520px) {
    ${'' /* width: 150px;
    height: 150px; */}
    flex: 0 0 calc(50% - 15px); /* 4 columns with gap consideration */
  }

`;

const Overlay = styled.div`
  /* Add styles for the overlay here if needed */
`;

const Image = styled.img`
  position: relative;
  top: 10px;
  width: 50%;
  height: 50%;
  object-fit: contain;
`;

const Title = styled.p`
  width: 55%;
  text-align: center;
  z-index: 98;
  ${'' /* font-family: "Anton, sans-serif"; */}
  ${'' /* font-size: 1.5rem; */}
  color: black;
  margin: 20px 5px 10px 5px;
  /* text-transform: uppercase; */

  @media (max-width: 1150px) {
    font-size: 1rem;
  }

  @media (max-width: 400px) {
    font-size: 0.8rem;
  }
`;

function ServiceCard(props) {
  return (
    <ServiceCardContainer className="serviceCard" key={props.id} to={`/products/${encodeURIComponent(kebabCase(props.title))}`}>
      <Overlay />
      <Image src={props.imgURL} alt="" />
      <Title>{props.title}</Title>
    </ServiceCardContainer>
  );
}

export default ServiceCard;
