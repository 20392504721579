import React from 'react';
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SectionLR from '../components/SectionLR';




function About() {

    return(
    <div>
        <NavBar />
        <Header 
      title = "ABOUT US"
      subtext = "At Unique Tools Company (UTC), we lead in providing top-notch industrial solutions in Saudi Arabia."
      imgURL = "/images/banners/header-01.webp"
    />
        <SectionLR 
            header="VISION"
            text="To be the leading company in the region, providing exceptional technical solutions, innovative equipment, and top-tier services to the industrial sector."
            image="/images/about/vision.webp"
            // image="url('/images/Stock photo.jpeg')"
            LR="default"
            // LR="default" -> text on the left
            // LR="flip" -> text on the right
        />

        <SectionLR 
            header="MISSION"
            text="We are dedicated to delighting our customers by delivering high-quality products and reliable services in a cost-effective manner, ensuring every interaction is a positive experience."
            image="/images/about/mission.webp"
            // image="url('/images/Stock photo.jpeg')"
            LR="flip"
            // LR="default" -> text on the left
            // LR="flip" -> text on the right
        />

        <SectionLR 
            header="STRATEGY"
            text="Our strategy is to expand our product range and enhance service capabilities to meet the evolving needs of the industrial sector, focusing on strong customer and supplier relationships and maintaining rigorous quality standards."
            image="/images/about/strategy.webp"
            // image="url('/images/Stock photo.jpeg')"
            LR="default"
            // LR="default" -> text on the left
            // LR="flip" -> text on the right
        />

        <SectionLR 
            header="VALUES"
            text="We prioritize quality, reliability, and innovation, focusing on customer satisfaction and integrity. Our commitment to these values drives us to deliver exceptional products and services consistently."
            image="/images/about/values.webp"
            // image="url('/images/Stock photo.jpeg')"
            LR="flip"
            // LR="default" -> text on the left
            // LR="flip" -> text on the right
        />

        <Footer />
    </div>
    )
}

export default About;