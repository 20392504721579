import React from 'react';
import { motion } from "framer-motion"
import "../css/heroLR.css"



function HeroLR(props) {


return(
    <div className={"heroLR "+ props.LR}>

        <img className="bg-img" src={props.bg}></img>

        <div className="text dm1">
        <motion.h1
        
        initial={{ y: 200, opacity:0 }}
        viewport={{ once: true }}
        whileInView={{ y: 0, opacity:1, 
        transition:{                
                type: "spring",
                duration: 2,
                delay:0,} }}
        >{props.header}</motion.h1>
        <motion.p
        
        initial={{ y: 200, opacity:0 }}
        viewport={{ once: true }}
        whileInView={{ y: 0, opacity:1, 
        transition:{                
                type: "spring",
                duration: 2,
                delay:0,} }}
        >{props.text}</motion.p>
        </div>



        <div className="image">

        <motion.img className="image-self" src={props.image} alt=''
        
        initial={{ y: 200, opacity:0 }}
        viewport={{ once: true }}
        whileInView={{ y: 0, opacity:1, 
        transition:{                
                type: "spring",
                duration: 2,
                delay:0.5,} }}
        ></motion.img>
        </div>
    </div>
)    
}

export default HeroLR;