import React from 'react';
import "../css/eto.css"


function Eto() { 

    return(
        <div className='eto-section'>
            <img src="/images/homepage/about-banner.webp"></img>
            <div className='overlay'>
                <h2>ABOUT US</h2>
                <p>At UTC, we're the go-to for top-notch tools and equipment in Saudi Arabia. Trusted by businesses regionally, we're dedicated to quality and excellence.</p>
            </div>
        </div>
    )
}

export default Eto;