import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { kebabCase } from 'lodash';
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ServiceCard from '../components/serviceCard';
import ProductCard from '../components/productCard';
import "../css/productPage.css";

function ProductDetailPage() {
  const { productTitle } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/data/ProductData.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setProducts(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching product data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const product = products.find(p => kebabCase(p.title) === productTitle);

  if (!product) {
    return <div>Product not found</div>;
  }

  // Get the next three products based on their IDs
  let nextProducts = products.filter(p => p.id > product.id);

  // If there are fewer than 3 projects, loop back to the beginning
  if (nextProducts.length < 4) {
    nextProducts = [
      ...nextProducts,
      ...products.slice(0, 4 - nextProducts.length)
    ];
  } else {
    nextProducts = nextProducts.slice(0, 4);
  }

  return (
    <div>
      <NavBar />
      <Header 
        title={product.title}
        subtext={product.description}
        imgURL="/images/banners/header-01.webp"
      />
      <div className='products-section product'>
        <div className='product-cards'>
          {product.images.map((productImages, index) => (
            <ProductCard
              key={index}
              imgURL={productImages}
            />
          ))}
        </div>
      </div>
      <div className='services-section service'>
        <h3>More Products</h3>
        <div className='service-cards next-products'>
          {nextProducts.map((nextProduct) => (
            <ServiceCard 
              key={nextProduct.id}
              id={nextProduct.id}
              title={nextProduct.title}
              imgURL={nextProduct.imgURL}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProductDetailPage;
