import React, { useEffect, useState } from 'react';
import "../css/products.css";
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ServiceCard from '../components/serviceCard';

function Products() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/data/ProductData.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setProducts(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching product data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    // return <div>Loading...</div>;
  }

  const featuredProducts = products
    .filter(product => product.featured)
    .sort((a, b) => a.featured - b.featured);

  return (
    <div>
      <NavBar />

      <Header 
        title="OUR PREMIUM PRODUCTS"
        subtext="UTC offers a wide selection of high-quality tools and equipment for various industrial needs."
        imgURL="/images/banners/header-01.webp"
      />

      <div className='services-section service'>
        <div className='service-cards'>
          {featuredProducts.map(product => (
            <ServiceCard
              key={product.id}
              id={product.id}
              title={product.title}
              imgURL={product.imgURL}
            />
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Products;
