import React from 'react';
import { motion } from "framer-motion"

import "../css/sectionLR.css"
import Carousel from './Carousel';



function SectionLRButton(props) {

        const carouselImages = [
                '/images/faw/faw1.webp',
                '/images/faw/faw2.webp',
                '/images/faw/faw3.webp',
                '/images/faw/faw4.webp',
                '/images/faw/faw5.webp',
                '/images/faw/faw6.webp',
                '/images/faw/faw7.webp',
                '/images/faw/faw8.webp',
                '/images/faw/faw9.webp'              ];


return(
    <div className={"section "+ props.LR}>

        <img className="bg-img" src={props.bg}></img>

        <div className="text dm1">
        <motion.h3
        initial={{ y: 0, opacity:0 }}
        viewport={{ once: true }}
        whileInView={{ y: 0, opacity:1, 
        transition:{                
                type: "spring",
                duration: 2,
                delay:0,} }}
        >{props.header}</motion.h3>
        
        <motion.p
        initial={{ y: 0, opacity:0 }}
        viewport={{ once: true }}
        whileInView={{ y: 0, opacity:1, 
        transition:{                
                type: "spring",
                duration: 2,
                delay:0,} }}
        >{props.text}</motion.p>

        <a href="/files/faw.pdf" target='_blank'>
        <button  className='button button-home button-lr'>View Catalogue</button>
        </a>



                {/* <motion.button
        
        initial={{ y: 0, opacity:0 }}
        viewport={{ once: true }}
        whileInView={{ y: 0, opacity:1, 
        transition:{                
                type: "spring",
                duration: 2,
                delay:0,} }}
        >Visit Page</motion.button> */}
        </div>



        <div className="image image-carousel">

        {/* <motion.img className="image-self" src={props.image} alt=''
        
        initial={{ y: 0, opacity:0 }}
        viewport={{ once: true }}
        whileInView={{ y: 0, opacity:1, 
        transition:{                
                type: "spring",
                duration: 2,
                delay:0,} }}
        ></motion.img> */}

        <Carousel 
        images= {carouselImages} />
        </div>
    </div>
)    
}

export default SectionLRButton;